import React, { useEffect, useState } from 'react';


const Post = ({ id }) => {
  const [post, setPost] = useState({});

  useEffect(() => {
    const getPost = async () => {
      //const resp = await fetch(`https://serverless-api.apphoster.workers.dev/api/posts/${id}`);
      //const postResp = await resp.json();
      //setPost(postResp);
    };

    getPost();
  }, [id]);

  if (!Object.keys(post).length) return <div />;

  return (
    <div>
      
    </div>
  );
};

export default Post;