import React, { useEffect, useState } from 'react';
import { startOfWeek, lastDayOfWeek } from 'date-fns';

const Posts = () => {
  const [posts, setPosts] = useState({});

  useEffect(() => {
    const getPosts = async () => {
      //const resp = await fetch('https://serverless-api.apphoster.workers.dev/api/posts');
      const resp = await fetch('http://127.0.0.1:8787/api/posts');
      
      const postsResp = await resp.json();
      console.log(postsResp);
      console.log(postsResp['nowInUTC']);
      window.nowInUTC = postsResp;
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    var weekNumber = Math.ceil(days / 7);
    postsResp.weekNumber = weekNumber;
    console.log(startOfWeek(new Date(), { weekStartsOn: 1 }));
    console.log(lastDayOfWeek(new Date(), { weekStartsOn: 1 }));
    postsResp.startOfWeek = startOfWeek(new Date(), { weekStartsOn: 1 }).toDateString();
    postsResp.lastDayOfWeek = lastDayOfWeek(new Date(), { weekStartsOn: 1 }).toDateString();

      setPosts(postsResp);
    };

    getPosts();
  }, []);

  return (
    <div>
      <h1>Posts</h1>
      Now in UTC: {posts.nowInUTC}
      <br></br>
      Week number UTC time: {posts.weekNumberInUTC}
      <br></br>
      Week number - local time: {posts.weekNumber}
      <br></br>
      Timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone}
      <br></br>
      startOfWeek: {posts.startOfWeek}
      <br></br>
      lastDayOfWeek: {posts.lastDayOfWeek}
    </div>
  );
};

export default Posts;